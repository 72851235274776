import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import Vue from 'vue'
import store from '@/store'

export default function useAdditionList() {
  // Use toast

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'person_name', label: '  الفرد', sortable: true },

    {
      key: 'family_card_number',
      label: '  ',

      sortable: true,
    },
    {
      key: 'action_type',
      label: '  ',

      sortable: true,
    },
    {
      key: 'add_on',
      label: '   ',

      sortable: true,
    },
    {
      key: 'created_by',
      label: ' قام بالتعديل ',
      formatter: title,
      sortable: true,
    },
    // { key: 'status', sortable: true },
    { key: 'action_id', label: 'الخيارات' },
  ]
  const perPage = ref(10)
  const selectedItem = ref([])
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 1000]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refFamiliesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const responseAll = (Additionlist, approvestate) => {
    // console.log(selectedItem)
    store
      .dispatch('orv/responseAll', {
        create_actions: Additionlist,
        approval_status: approvestate,

      })
      .then(response => {
        selectedItem.value = []
        // console.log(selectedItem)

        Vue.swal({
          title: `${response.data.message}`,
          icon: 'success',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        refetchData()
      })
      .catch(error => {
        Vue.swal({
          text: `${error.response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  const onFiltered = filteredItems => {
    // Trigger pagination to update the number of buttons/pages due to filtering
    totalUsers.value = filteredItems.length
    currentPage.value = 1
  }

  const sendResponse = (action_id, approvestate) => {
    store
      .dispatch('orv/sendResponseaAdd', {
        action_id,
        approvestate,

      })
      .then(response => {
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'success',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        refetchData()
      })
      .catch(error => {
        Vue.swal({
          text: `${error.response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  // const products = ref([])

  const fetchAddition = (ctx, callback) => {
    store
      .dispatch('orv/fetchAddition', {

        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data
        totalUsers.value = response.data.meta.total
        callback(data)
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }

  return {
    fetchAddition,
    onFiltered,
    selectedItem,
    tableColumns,
    perPage,
    currentPage,
    sendResponse,
    responseAll,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  }
}

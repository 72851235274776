import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchORV(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/orv/family-visit', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAddition(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/orv_coordinator/pending_create_actions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



  sendResponseaAdd(ctx, approvestate) {
    return new Promise((resolve, reject) => {
      const data = {
        approval_status: approvestate.approvestate,
      }

      const url = `/api/v1/orv_coordinator/createActions/${approvestate.action_id}`
      axios
        .put(url, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  responseAll(ctx, approvestate) {
    // console.log(approvestate)
    return new Promise((resolve, reject) => {
      const url = '/api/v1/orv_coordinator/createActions/respond_multiple'
      axios
        .put(url, approvestate)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},
}
